import * as React from "react";
import {
    Edit,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRecordContext,
    useRedirect
} from 'react-admin';
import {RichTextInput} from 'ra-input-rich-text';
import dataProvider from "../dataProvider";
import {WrappedWithBackButton} from "../wrapped-with-back-button";

const CustomToolbar = (props: any) => (
    <Toolbar
        {...props}
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
    </Toolbar>
);

const ParamForm = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    return <SimpleForm toolbar={<CustomToolbar/>}
                       onSubmit={async (values) => {
                           const neededValues = {
                               name: values.name,
                               type: record.type,
                               value: values.value.toString(),
                           }
                           await dataProvider.update('system-params', {id: record.id, data: neededValues}).then(res => {
                               if (res.data) {
                                   notify(`Sikeres módosítás`, {type: 'success'});
                                   redirect('list', 'system-params');
                               } else {
                                   notify(`Hiba történt`, {type: 'error'});
                               }
                           })
                       }}
    >
        <TextInput source="name" label={"Név"} name={"name"} fullWidth required disabled/>
        <FormDataConsumer>
            {({formData, ...rest}) => formData.type === 'Text' &&
                <TextInput source="value" label={"Érték"} name={"value"} fullWidth required/>
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {({formData, ...rest}) => formData.type === 'RichText' &&
                <RichTextInput source="value" label={"Érték"} name={"value"} fullWidth isRequired/>
            }
        </FormDataConsumer>
        <FormDataConsumer>
            {({formData, ...rest}) => formData.type === 'Number' &&
                <NumberInput source="value" label={"Érték"} name={"value"} fullWidth required/>
            }
        </FormDataConsumer>
    </SimpleForm>
}

export const ParamEdit = () => {
    return <WrappedWithBackButton>
        <Edit title={'Rendszer paraméter szerkesztése'}>
            <ParamForm/>
        </Edit>
    </WrappedWithBackButton>
}
