import * as React from "react";
import {Datagrid, DateField, FunctionField, List, Pagination, TextField, TopToolbar} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {useState} from "react";
import { Dialog } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import ReactDiffViewer from 'react-diff-viewer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const useStyles = makeStyles(_theme => ({
    newColumn: {
        maxWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 20,
    },
}));

export const LogList = () => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [log, setLog] = useState({ _oldValue: null, _newValue: null });

    function handleOpenDialog(data: any) {
        setLog({ _oldValue: data.oldValue, _newValue: data.newValue });
        setDialogOpen(true);
    }

    function handleClose() {
        setDialogOpen(false);
    }
    return <>
        <List title={'Napló'} exporter={false}
          sort={{field: 'createdAt', order: 'DESC'}}
    >
        <Datagrid bulkActionButtons={false} rowClick={undefined}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="entity" label={"Entitás"} sortable={false}/>
            <FunctionField render={(data:any) => {
                return <div className={classes.newColumn}>{data.oldValue}</div>;
            }} label="Régi"/>
            <FunctionField render={(data:any) => {
                return <div className={classes.newColumn}>{data.newValue}</div>;
            }} label="Új"/>
            <FunctionField render={(data:any) => {
                return <div onClick={() => handleOpenDialog(data)}><VisibilityOutlinedIcon sx={{color: '#FFF'}}/></div>;
            }}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
        </Datagrid>
    </List>

        <Dialog onClose={handleClose}
                aria-labelledby="dialog-title"
                open={dialogOpen}
                maxWidth="lg"
                TransitionComponent={Grow}>
            <DialogTitle id="dialog-title">Változások</DialogTitle>
            <DialogContent>
                <ReactDiffViewer oldValue={log._oldValue || ''} newValue={log._newValue || ''} splitView hideLineNumbers/>
            </DialogContent>
        </Dialog>
    </>
};
