import * as React from "react";
import {useEffect, useState} from "react";
import {
    Datagrid,
    DateField,
    DateTimeInput,
    downloadCSV,
    FunctionField,
    List,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {config} from "../config";
import {getJwtHeader} from "../auth/authProvider";
import jsonExport from 'jsonexport/dist';

export const getSurveyType = (type: string) => {
    switch (type) {
        case "FlatRate":
            return "Átalány fogyasztási érték bejelentés"
        case "LevelDeclaration":
            return "Mérőállás bejelentés"
        case "Invoice":
            return "Számla befizetés"
        case "PaybackNumber":
            return "Visszautalási számlaszám megadás"
        default:
            return "-"
    }
}

const surveyFilters = [
    <SelectInput source="type" label={"Ügytípus"}
                 choices={[
                     {id: 'FlatRate', name: 'Átalány fogyasztási érték bejelentés'},
                     {id: 'LevelDeclaration', name: 'Mérőállás bejelentés'},
                     {id: 'Invoice', name: 'Számla befizetés'},
                     {id: 'PaybackNumber', name: 'Visszautalási számlaszám megadás'},
                 ]}/>,
    <TextInput source="email" label={"E-mail"} name={"email"}/>,
    <DateTimeInput source="from" name={"from"} label={"Beküldve ettől"}/>,
    <DateTimeInput source="until" name={"until"} label={"Beküldve eddig"}/>
];

const exporter = (records: any[], fetchRelatedRecords: (arg0: any, arg1: string, arg2: string) => Promise<any>) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords([], 'id', 'survey').then((surveys: any) => {
        const data = records.map((record: any) => ({
            'providerId': null,
            'providerName': null,
            'transactionId': record.id,
            'placeId': null,
            'placeName': null,
            'placeAddress': null,
            'number': record.id,
            'enterTime': record.createdAt,
            'serviceName1': record.type,
            'serviceName2': null,
            'staffName': null,
            'workstationCode': null,
            'startTime': record.startAt,
            'nextTime': record.createdAt,
            'endTime': record.createdAt,
            'tableXTime': null,
            'tableX': null,
            'mailXTime': null,
            'mailX': null,
            'question': record.question,
            'onLineXTime': record.createdAt,
            'onLineX': record.answer,
        }));
        return jsonExport(data, {
            headers: [
                'providerId',
                'providerName',
                'transactionId',
                'placeId',
                'placeName',
                'placeAddress',
                'number',
                'enterTime',
                'serviceName1',
                'serviceName2',
                'staffName',
                'workstationCode',
                'startTime',
                'nextTime',
                'endTime',
                'tableXTime',
                'tableX',
                'mailXTime',
                'mailX',
                'question',
                'onLineXTime',
                'onLineX',
            ],
            rename: [
                'Szolgáltató azonosító',
                'Szolgáltató neve',
                'TransactionID',
                'Helyszín azonosítója',
                'Helyszín neve',
                'Helyszín címe',
                'Number',
                'EnterTime',
                'ServiceName1',
                'ServiceName2',
                'StaffName',
                'WorkstationCode',
                'StartTime',
                'NextTime',
                'EndTime',
                'TabletXtime',
                'TabletX',
                'MailXtime',
                'MailX',
                'Kérdés',
                'OnLineXtime',
                'OnLineX',
            ],
        }, (err, csv) => {
            downloadCSV(csv, 'elegedettseg_meres');
        });
    });
};


export const SurveyList = () => {
    const [questions, setQuestions] = useState([])
    useEffect(() => {
        async function fetchQuestions() {
            let response = await fetch(`${config.apiUrl}/survey/all`, {
                headers: {
                    'Authorization': getJwtHeader()
                }
            })
            response = await response.json()
            setQuestions((response as any).items.map((it: any) => {
                return {
                    id: it.questionId,
                    name: it.question,
                }
            }))
        }

        fetchQuestions()
    }, [])


    return <List title={'Elégedettség mérések'}
                 exporter={exporter}
                 sort={{field: 'createdAt', order: 'DESC'}}
                 filters={[...surveyFilters, <SelectInput source="questionId" label={"Kérdés"}
                                                          choices={questions}/>]}>
        <Datagrid rowClick={undefined} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="question" label={"Kérdés"}/>
            <TextField source="answer" label={"Válasz"}/>
            <FunctionField
                label="Ügytípus"
                render={(record: any) => `${getSurveyType(record.type)}`}
            />
            <FunctionField
                sortable={false}
                label="Felhasználó"
                render={(record: any) => `${record.user?.email ? record.user.email : 'Törölt felhasználó'}`}
            />
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"} showTime/>
        </Datagrid>
    </List>
};
