import {Menu, useGetList, useTheme} from 'react-admin';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import {useLocation} from "react-router-dom";

interface MenuItemWithNumberOfNewItemsProps {
    to: string
    primaryText: string
    leftIcon: any
    sx: any
}

const MenuItemWithNumberOfNewItems = (props: MenuItemWithNumberOfNewItemsProps) => {
    const { data, total, isLoading, error } = useGetList(
        props.to,
        {
            pagination: { page: 1, perPage: 1000 },
            filter: {
                status: 'Free'
            }
        }
    );
    return <Menu.Item to={props.to}
                      primaryText={`${props.primaryText} (${data?.length})`}
                      leftIcon={props.leftIcon}
                      sx={props.sx}/>

}

export const CustomMenu = () => {
    const [theme] = useTheme();
    // @ts-ignore
    const mainColor = theme.palette?.primary?.main ?? "#42C9AD"

    const activeStyle = {
        '&.RaMenuItemLink-active': {
            border: `2px solid ${mainColor}`,
            color: mainColor,
        },
    }
    let location = useLocation();

    return <Menu>
        <Menu.Item to="/document-group" primaryText="Ügylettípusok" leftIcon={<ListAltOutlinedIcon/>} sx={location.pathname.includes('/document-group') ? activeStyle : {}}/>
        <Menu.Item to="/document" primaryText="Nyomtatványok" leftIcon={<ArticleOutlinedIcon/>} sx={activeStyle}/>
        <MenuItemWithNumberOfNewItems to="/document-request" primaryText="Változásbejelentések" leftIcon={<PendingActionsIcon/>} sx={location.pathname.includes('/document-request') ? activeStyle : {}}/>
        <Menu.Item to="/request/_EInvoice" primaryText="Elektronikus számla igénylés" leftIcon={<AlternateEmailOutlinedIcon/>} sx={activeStyle}/>
        <MenuItemWithNumberOfNewItems to="/request/_DebtFree" primaryText="Tartozásmentességi igazolás igénylés" leftIcon={<ReceiptLongOutlinedIcon/>} sx={{...activeStyle, fontSize: '15px'}}/>
        <MenuItemWithNumberOfNewItems to="/request/_PostInvoice" primaryText="Postai számlaigénylés" leftIcon={<MarkunreadMailboxOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/survey" primaryText="Elégedettség mérés" leftIcon={<InsertDriveFileOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/news" primaryText="Hírek" leftIcon={<NewspaperOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/email-template" primaryText="Email sablonok" leftIcon={<EmailOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/log" primaryText="Napló" leftIcon={<CalendarMonthOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/issue-report" primaryText="Hibabejelentés" leftIcon={<ReportGmailerrorredIcon/>} sx={activeStyle}/>
        <Menu.Item to="/web-user" primaryText="Felhasználók" leftIcon={<PersonOutlineOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/admin-user" primaryText="Admin felhasználók" leftIcon={<SupervisorAccountOutlinedIcon/>} sx={activeStyle}/>
        <Menu.Item to="/system-params" primaryText="Rendszer paraméterek" leftIcon={<DesignServicesOutlinedIcon/>} sx={activeStyle}/>
    </Menu>
};
