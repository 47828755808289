import * as React from "react";
import {Datagrid, List, TextField} from 'react-admin';

export const DocumentGroupList = () => (
    <List title={'Ügylettípusok'} exporter={false}>
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"} sortable={false}/>
        </Datagrid>
    </List>
);
