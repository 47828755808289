import * as React from "react";
import {Datagrid, FunctionField, List, SelectInput, TextField, TextInput} from 'react-admin';

export const documentTypes = [
    {id: 'Request', name: 'Kérés'},
    {id: 'PersonalContract', name: 'Szerződés'},
    {id: 'PostInvoice', name: 'Postai számla'},
    {id: 'Error', name: 'Hiba'},
    {id: 'Unique', name: 'Egyedi'},
    {id: 'Maintenance', name: 'Karbantartás'},
    {id: 'OverPay', name: 'Túlfizetés'},
]

const documentFilters = [
    <TextInput source="name" label={"Név"} name={"name"} fullWidth required/>,
    <SelectInput source="type"
                 label={"Típus"}
                 choices={documentTypes}/>
];

export const getDocumentTypeName = (type: string) => {
    switch (type) {
        case "Request":
            return "Kérés"
        case "PersonalContract":
            return "Szerződés"
        case "PostInvoice":
            return "Postai számla"
        case "Error":
            return "Hiba"
        case "Unique":
            return "Egyedi"
        case "Maintenance":
            return "Karbantartás"
        case "OverPay":
            return "Túlfizetés"
        default:
            return "-"
    }
}

export const DocumentList = () => (
    <List title={'Nyomtatványok'} exporter={false} filters={documentFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"} sortable={false}/>
            <TextField source="url" label={"URL"} sortable={false}/>
            <FunctionField
                label="Típus"
                render={(record: any) => `${getDocumentTypeName(record.type)}`}
            />
        </Datagrid>
    </List>
);
