export const getInvoiceStatusName = (type: string) => {
    switch(type) {
        case "Paid":
            return "Fizetve"
        case "UnPaid":
            return "Nincs fizetve"
        case "Pending":
            return "Fizetés alatt"
        case "Failed":
            return "Hibás fizetés"
        case "Fulfilled":
            return "Jóváírva"
        default:
            return "-"
    }
}

export const getInvoiceTransactionStatusName = (type: string) => {
    switch(type) {
        case "Paid":
            return "Fizetve"
        case "Pending":
            return "Fizetés alatt"
        case "Failed":
            return "Hibás fizetés"
        default:
            return "-"
    }
}

export const getRequestStatusName = (status: string) => {
    switch(status) {
        case "Free":
            return "Foglalható"
        case "InProgress":
            return "Folyamatban"
        case "UnderDuty":
            return "Feldolgozás alatt"
        case "Closed":
            return "Lezárva"
        default:
            return "-"
    }
}
