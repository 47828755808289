import * as React from "react";
import {Datagrid, DateField, DeleteWithConfirmButton, FunctionField, List, TextField, TextInput} from 'react-admin';

const userFilters = [
    <TextInput source="name" label={"Név"} name={"name"} fullWidth required/>,
    <TextInput source="email" label={"E-mail"} name={"email"} fullWidth required/>
];

export const AdminUserList = () => (
    <List title={'Admin felhasználók'}
          sort={{field: 'createdAt', order: 'DESC'}}
          filters={userFilters}
          exporter={false}
    >
        <Datagrid bulkActionButtons={false} rowClick={"edit"}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="email" label={"E-mail"}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
            <FunctionField
                label=""
                render={(record: any) => {
                    return record.id < 1 ? null : <DeleteWithConfirmButton
                        confirmTitle={"Admin felhasználó eltávolítása"}
                        confirmContent={"A felhasználó véglegesen kikerül az admin felhasználók közül"}
                    />
                }}
            />
        </Datagrid>
    </List>
);
