import * as React from 'react';
import {DateField, FunctionField, Show, SimpleShowLayout, TextField, useShowContext} from 'react-admin';
import {WrappedWithBackButton} from "../wrapped-with-back-button";

const SurveyShowLayout = () => {
    const {
        error,
        isLoading,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="question" label={"Kérdés"}/>
            <TextField source="answer" label={"Válasz"}/>
            <FunctionField
                sortable={false}
                label="Felhasználó név"
                render={(record: any) => `${record.user?.name ? record.user.name : 'Törölt felhasználó'}`}
            />
            <FunctionField
                sortable={false}
                label="Felhasználó e-mail"
                render={(record: any) => `${record.user?.email ? record.user.email : 'Törölt felhasználó'}`}
            />
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"} showTime/>
        </SimpleShowLayout>
    );
};


export const SurveyShow = () => {
    return <WrappedWithBackButton>
        <Show title={'Elégedettség mérés'}>
            <SurveyShowLayout/>
        </Show>
    </WrappedWithBackButton>;
};
