import * as React from "react";
import {
    Datagrid,
    DateField, DateInput, DateTimeInput,
    DeleteWithConfirmButton, downloadCSV,
    FunctionField,
    List,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import jsonExport from "jsonexport/dist";

const userFilters = [
    <TextInput source="name" label={"Név"} name={"name"} fullWidth required/>,
    <TextInput source="email" label={"E-mail"} name={"email"} fullWidth required/>,
    <DateTimeInput source="from" name={"registeredFrom"} label={"Regisztrált ettől"}/>,
    <DateTimeInput source="until" name={"registeredFUntil"} label={"Regisztrált eddig"}/>
];

const exporter = (records: any[], fetchRelatedRecords: (arg0: any, arg1: string, arg2: string) => Promise<any>) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords([], 'id', 'web-user').then((users: any) => {
        const data = records.map((record: any) => ({
            ...record
        }));
        return jsonExport(data, {
            headers: [
                'id',
                'name',
                'phone',
                'email',
                'registeredAt',
                'taxNumber',
            ],
            rename: [
                'ID',
                'Név',
                'Telefonszám',
                'E-mail',
                'Regisztrálás ideje',
                'Adószám',
            ],
        }, (err, csv) => {
            downloadCSV(csv, 'felhasznalok');
        });
    });
};


export const WebUserList = () => (
    <List title={'Felhasználók'}
          exporter={exporter}
          sort={{field: 'registeredAt', order: 'DESC'}}
          filters={userFilters}
          pagination={false}
          sx={{marginBottom: '50px'}}
    >
        <Datagrid bulkActionButtons={false} rowClick={"show"}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="email" label={"E-mail"}/>
            <DateField source="registeredAt" locales={['hu-HU']} label={"Regisztrálás ideje"}/>
            <FunctionField
                label=""
                render={(record:any) => {
                    return record.id < 1 ? null : <DeleteWithConfirmButton
                        confirmTitle={"Felhasználó eltávolítása"}
                        confirmContent={"A felhasználó véglegesen törlődik"}
                    />
                }}
            />
        </Datagrid>
    </List>
);
