import * as React from "react";
import {Datagrid, List, TextField, TopToolbar} from 'react-admin';

export const EmailTemplateList = () => (
    <List title={'Email sablonok'}
          actions={
              <TopToolbar/>
          }
    >
        <Datagrid bulkActionButtons={false} rowClick={"edit"}>
            <TextField source="hungarianName" label={"Név"} sortable={false}/>
            <TextField source="subject" label={"Cím"}/>
        </Datagrid>
    </List>
);
