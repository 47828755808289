import dataProvider from "./dataProvider";

const customSaveResources = ['news', 'document', 'document-request', 'issue-report']

const getFileData = (resource: string, params: any) => {
    switch (resource) {
        case 'news':
            return params.data.image
        case 'document':
            return params.data.file
        case 'document-request':
            return params.data.files
        case 'issue-report':
            return params.data.files
        default:
            return null
    }
}

const myDataProvider = {
    ...dataProvider,
    create: (resource: any, params: any) => {
        if (!customSaveResources.includes(resource)) {
            return dataProvider.create(resource, params);
        }
        if (resource === 'news' && params.data.image === null) {
            return Promise.reject(new Error('Kép megadása kötelező'))
        }
        if (resource === 'document' && params.data.file === null) {
            return Promise.reject(new Error('Fájl megadása kötelező'))
        }
        const file = getFileData(resource, params)
        const files = [file];
        const newFiles = files.filter(
            (p: any) => p.rawFile instanceof File
        );

        return Promise.all(newFiles.map(convertFileToBase64))
            .then(transformedNewFiles => {
                    const customData = resource === 'news' ? {
                        image: transformedNewFiles[0],
                    } : {
                        url: transformedNewFiles[0],
                    }

                    let sendData = {};
                    if (resource === 'document') {
                        sendData = {
                            name: params.data.name,
                            type: params.data.type,
                            ...customData,
                        }
                    } else {
                        sendData = {
                            ...params.data,
                            ...customData,
                        }
                    }

                    return dataProvider.create(resource, {
                        ...params,
                        data: sendData,
                    })
                }
            );
    },
    update: (resource: any, params: any) => {
        if(resource === 'request') {
            const correctParams = {
                id: params.id,
                data: {
                    note: params.data.insideNote
                }
            }
            return dataProvider.update(resource, correctParams);
        }
        if (!customSaveResources.includes(resource)) {
            return dataProvider.update(resource, params);
        }
        const file = getFileData(resource, params)
        const files = file ? (resource === 'document-request' || resource === 'issue-report') ? file : [file] : [];
        const newFiles = files.filter(
            (p: any) => p.rawFile instanceof File
        );

        return Promise.all(newFiles.map(convertFileToBase64))
            .then(transformedNewFiles => {
                    const customData = resource === 'news' ? {
                        image: newFiles.length === 0 ? params.data.image : transformedNewFiles[0],
                    } : resource === 'document' ? {
                        url: newFiles.length === 0 ? params.data.url : transformedNewFiles[0],
                    } : {
                        adminFiles: newFiles.length === 0 ? params.data.files : transformedNewFiles.map((tf, index) => {
                            return {
                                name: files[index].fileTitle,
                                url: tf
                            }
                        })
                    }
                let sendData:any = {};
                if (resource === 'document') {
                    sendData = {
                        name: params.data.name,
                        type: params.data.type,
                        ...customData,
                    }
                } else if (resource === 'news') {
                    sendData = {
                        ...params.data,
                        ...customData,
                    }
                } else if (resource === 'document-request'){
                    const adminfilesArray = typeof customData.adminFiles !== 'undefined' ? [...params.data.adminFiles, ...customData.adminFiles] : params.data.adminFiles
                    sendData = {
                        note: params.data.insideNote,
                        adminFiles: adminfilesArray ?? []
                    }
                } else {
                    const adminfilesArray = typeof customData.adminFiles !== 'undefined' ? [...params.data.adminFiles, ...customData.adminFiles] : params.data.adminFiles
                    sendData = {
                        insideNote: params.data.insideNote,
                        adminFiles: adminfilesArray ?? []
                    }
                }

                return dataProvider.update(resource, {
                    ...params,
                    data: sendData,
                })
                }
            );
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export default myDataProvider;
