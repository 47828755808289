import * as React from 'react';
import {
    BooleanField,
    DateField,
    EditButton,
    ImageField,
    Show,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useShowContext
} from 'react-admin';
import {Typography} from "@mui/material";
import {WrappedWithBackButton} from "../wrapped-with-back-button";

const NewsShowActions = () => (
    <TopToolbar>
        <EditButton/>
    </TopToolbar>
);

const NewsShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"}/>
            <DateField source="publishedFrom" label={"Publikálva ettől"} locales={['hu-HU']}/>
            <DateField source="publishedUntil" label={"Publikálva eddig"} locales={['hu-HU']}/>
            <BooleanField source={"isActive"} label={"Aktív"}/>
            <TextField source="title" label={"Cím"}/>
            <TextField source="lead" label={"Alcím"}/>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Tartalom</Typography>
            <div dangerouslySetInnerHTML={{__html: record.content}}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
            <img src={`https://${record.image}`} height={120} width={250}/>
        </SimpleShowLayout>
    );
};


export const NewsShow = () => {
    return <WrappedWithBackButton>
        <Show title={'Hír'} actions={<NewsShowActions/>}>
            <NewsShowLayout/>
        </Show>
    </WrappedWithBackButton>;
};
