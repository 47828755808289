import {fetchUtils} from 'react-admin';
import {stringify} from 'query-string';
import {getJwtHeader} from "./auth/authProvider";
import {config} from "./config";

// export const apiUrl = 'http://127.0.0.1:4010';
export const apiUrl = config.apiUrl;
const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    options.headers.set('Authorization', getJwtHeader());
    return fetchUtils.fetchJson(url, options);
};

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {

    getList: (resource: any, params: any) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            page: page,
            size: perPage,
            order: order,
            sort: capitalizeFirstLetter(field),
            ...params.filter
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => {
            return {
                data: json.items,
                // @ts-ignore
                total: json.totalCount,
            }
        });
    },

    getOne: (resource: any, params: any) => {
        if(resource === 'email-template' || resource === 'request/_DebtFree' || resource === 'request/_PostInvoice') {
            let res = resource;
            if(resource === 'request/_DebtFree' || resource === 'request/_PostInvoice') {
                res = 'request'
            }
            // no underline
            return httpClient(`${apiUrl}/${res}/${params.id}`).then(({json}) => ({
                data: json,
            }))
        }
        return httpClient(`${apiUrl}/${resource}/_${params.id}`).then(({json}) => ({
            data: json,
        }))
    },

    getMany: (resource: any, params: any) => {
        const query = {
            // filter: JSON.stringify({ ids: params.ids }),
            size: params.ids.length,
            page: 1
        };
        let idsString = '';
        for (let i = 0; i < params.ids.length; i++) {
            idsString += `ids=${params.ids[i]}`;
            if (i < params.ids.length - 1) {
                idsString += '&'
            }
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}&${idsString}`;
        return httpClient(url).then(({json}) => ({data: json.items}));
    },

    getManyReference: (resource: any, params: any) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => ({
            data: json,
            // @ts-ignore
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    create: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({
            data: {...params.data, id: json.id},
        })),

    update: (resource: any, params: any) => {
        if(resource === 'email-template' || resource === 'request') {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({json}) => ({data: json}))
        }
        return httpClient(`${apiUrl}/${resource}/_${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}))
    },

    updateMany: (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    delete: (resource: any, params: any) =>
        httpClient(`${apiUrl}/${resource}/_${params.id}`, {
            method: 'DELETE',
        }).then(({json}) => ({data: json})),

    deleteMany: (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },
};
