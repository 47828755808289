import * as React from "react";
import {RichTextInput} from 'ra-input-rich-text';
import {Edit, SimpleForm, TextInput, useNotify, useRecordContext, useRedirect, useRefresh,} from 'react-admin';
import dataProvider from "../dataProvider";
import {BackButton} from "../back-button";
import {WrappedWithBackButton} from "../wrapped-with-back-button";

const EmailTemplateForm = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();
    return <>
        <SimpleForm
            onSubmit={async (values) => {
                const neededValues = {
                    subject: values.subject,
                    content: values.content,
                }
                await dataProvider.update('email-template', {id: record.id, data: neededValues}).then(res => {
                    if (res.data) {
                        notify(`Sikeres módosítás`, {type: 'success'});
                        redirect('list', 'email-template');
                        refresh();
                    } else {
                        notify(`Hiba történt`, {type: 'error'});
                    }
                })
            }}>
            <TextInput source="subject" label={"Cím"} name={"subject"} fullWidth required/>
            <RichTextInput source="content" label={"Tartalom"} name={"content"} fullWidth isRequired/>
        </SimpleForm>
    </>
}

export const EmailTemplateEdit = () => (
    <WrappedWithBackButton>
        <Edit title={'Email sablon szerkesztése'}>
            <EmailTemplateForm/>
        </Edit>
    </WrappedWithBackButton>
);
