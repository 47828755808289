import {useNavigate} from "react-router-dom";
import React from "react";
import {Button} from "react-admin";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

export const BackButton = () => {
    let navigate = useNavigate();
    return <Button label={"Vissza"}
                   startIcon={<ArrowBackIosNewOutlinedIcon/>}
                   onClick={() => navigate(-1)}
                   sx={{
                       fontSize: 16,
                       width: 100,
                       marginTop: '10px',
                   }}
    />
};
