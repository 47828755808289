import * as React from 'react';
import {
    ArrayField, Button,
    Datagrid,
    DateField,
    FunctionField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField, useNotify, useRefresh,
    useShowContext
} from 'react-admin';
import {getIssueTypeName} from "./issue-report-list";
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {Box} from "@mui/material";
import {EditDialogWithAdminFiles} from "../document-request/EditDialogWithAdminFiles";
import {Grid} from "@material-ui/core";
import {useState} from "react";
import myDataProvider from "../data-provider-with-image-convert";

const IssueReportShowLayout = () => {
    const {
        error,
        isLoading,
        record
    } = useShowContext();
    const notify = useNotify();
    const refresh = useRefresh();

    const [dialogOpen, setDialogOpen] = useState(false)

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"} sortable={false}/>
            <ReferenceField source="user.id"
                            reference="web-user"
                            link="show"
                            label={"Felhasználó"}>
                <TextField source="name"/>
            </ReferenceField>
            <FunctionField
                label="Típus"
                render={(record: any) => `${getIssueTypeName(record.type)}`}
            />
            <TextField source="gateCode" label={"Kapukód"} sortable={false}/>
            <TextField source="message" label={"Üzenet"} sortable={false}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozás ideje"} showTime/>
            <div style={{color: '#B2BAC2', fontSize: '12px'}}>Csatolmányok</div>
            {
                record.attachments.map((url: string) => {
                    return <a href={`https://${url}`} target={'_blank'} style={{color: '#FFF'}}>{url}</a>
                })
            }
            <TextField source="insideNote" label={"Admin megjegyzés"}/>
            <div style={{color: '#B2BAC2', fontSize: '12px'}}>Admin fájlok</div>
            {record?.adminFiles?.map((d: any) => {
                return <Grid container>
                    <Grid xs={12} md={3} style={{fontWeight: 600}}>{d.name}</Grid>
                    <Grid xs={12} md={6}><a href={`https://${d.url}`} style={{color: '#fff'}}
                                            target={'_blank'}>{`https://${d.url}`}</a></Grid>
                    <Grid xs={12} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Button variant={'outlined'}
                                label={'Törlés'}
                                style={{
                                    color: '#EB0014',
                                    border: '1px solid #EB0014'
                                }}
                                onClick={async () => {
                                    await myDataProvider.update('issue-report', {
                                        id: record.id,
                                        data: {
                                            insideNote: record.insideNote,
                                            adminFiles: record.adminFiles.filter((f:any) => f.url !== d.url)
                                        }
                                    }).then(res => {
                                        notify(`Fájl sikeresen eltávolítva`, {type: 'success'});
                                        refresh()
                                    })
                                }}
                        /></Grid>
                </Grid>
            })}
            <Box sx={{height: '5px'}}/>
            <Button variant={'contained'} label={'Megjegyzés/fájlok szerkesztése'} onClick={() => setDialogOpen(true)}
                    style={{marginTop: '5px'}}/>
            {record && <EditDialogWithAdminFiles resource={'issue-report'} record={record} open={dialogOpen}
                                                 setOpen={setDialogOpen}/>}
        </SimpleShowLayout>
    );
};


export const IssueReportShow = () => {
    return <WrappedWithBackButton>
        <Show title={'Hibabejelentés'}>
            <IssueReportShowLayout/>
        </Show>
    </WrappedWithBackButton>;
};
