import * as React from "react";
import {RichTextInput} from 'ra-input-rich-text';
import {
    BooleanInput, Button,
    Create,
    DateInput,
    Edit, FormDataConsumer,
    ImageField,
    ImageInput,
    SimpleForm,
    TextInput,
    useRecordContext,
} from 'react-admin';
import {Box, Typography} from "@mui/material";
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {useState} from "react";
import {Dialog} from "@material-ui/core";

const NewsForm = () => {
    const record = useRecordContext();
    const [previewOpen, setPreviewOpen] = useState(false)

    return <SimpleForm>
        <DateInput source="publishedFrom" name={"publishedFrom"} label={"Publikálva ettől"} required/>
        <DateInput source="publishedUntil" name={"publishedUntil"} label={"Publikálva eddig"} required/>
        <BooleanInput source="isActive" label={"Aktív"} defaultValue={true} name={"isActive"}/>
        <TextInput source="title" label={"Cím"} name={"title"} fullWidth required/>
        <TextInput source="lead" label={"Alcím"} name={"lead"} fullWidth required/>
        <RichTextInput source="content" label={"Tartalom"} name={"content"} fullWidth isRequired/>
        <ImageField source="src" title="title"/>
        {(record && record.hasOwnProperty("image")) && <Box>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi kép</Typography>
            <img src={`https://${record.image}`} alt={"current news picture"}
                 style={{margin: '10px 0px', maxHeight: '200px'}}/>
        </Box>
        }
        <ImageInput source="image" label="Index kép" accept="image/*" placeholder={<p>Kép megadása</p>} name={"image"}
                    isRequired>
            <ImageField source="src" title="title"/>
        </ImageInput>
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return <>
                    <Button label={'Előnézet'}
                            onClick={() => setPreviewOpen(true)}
                            size={'medium'}
                            variant={'contained'}
                            sx={{marginBottom: '20px'}}
                            disabled={!(formData.title && formData.lead && formData.content)}
                    />
                    <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
                        <Box sx={{minWidth: '500px', padding: '20px'}}>
                            <Box sx={{fontWeight: 700, fontSize: 18, marginBottom: '10px'}}>Előnézet</Box>
                            <Box style={{border: '1px solid gray', borderRadius: '10px', padding: '10px'}}>
                                {formData.image && <img src={formData.image?.src ?? formData.image} height={200} width={'100%'} style={{borderRadius: 10}}/>}
                                <Box sx={{fontWeight: 700, fontSize: 16, margin: '10px 0'}}>{formData.title}</Box>
                                <Box sx={{fontWeight: 700, fontSize: 13, marginBottom: '20px'}}>{formData.lead}</Box>
                                <div dangerouslySetInnerHTML={{__html: formData.content}}/>
                            </Box>
                        </Box>
                    </Dialog>
                </>
            }
            }
        </FormDataConsumer>
    </SimpleForm>
}

export const NewsCreate = () => (
    <WrappedWithBackButton>
        <Create title={'Hír létrehozása'}>
            <NewsForm/>
        </Create>
    </WrappedWithBackButton>
);

export const NewsEdit = () => (
    <WrappedWithBackButton>
        <Edit title={'Hír szerkesztése'}>
            <NewsForm/>
        </Edit>
    </WrappedWithBackButton>
);
