import * as React from "react";
import {useEffect} from "react";
import {
    Datagrid,
    DateField,
    DateTimeInput,
    FunctionField,
    List,
    ReferenceField,
    SelectArrayInput,
    TextField,
    TextInput,
    useRedirect,
    useRefresh
} from 'react-admin';
import {useLocation} from "react-router-dom";
import {getRequestStatusName} from "../enum-translates";
import {Chip} from "@material-ui/core";
import {getStatusColor} from "../StatusHistory";
import {Button, Tooltip} from "@mui/material";
import {getJwtData} from "../auth/authProvider";
import {exportExcel} from "../export-excel";

export interface RequestListProps {
    title: string
    type: string
}

const requestFilters = [
    <TextInput source="query" label={"Név/E-mail"} name={"query"}/>,
    <SelectArrayInput source="status"
                      label={"Státusz"}
                      choices={[
                          {id: 'Free', name: 'Foglalható'},
                          {id: 'InProgress', name: 'Folyamatban'},
                          {id: 'UnderDuty', name: 'Feldolgozás alatt'},
                          {id: 'Closed', name: 'Lezárva'},
                      ]}
                      defaultValue={[
                          'Free',
                          'InProgress',
                          'UnderDuty'
                      ]}
                      key={2}/>,
    <DateTimeInput source="dateFrom" name={"dateFrom"} label={"Dátum ettől"} key={3}/>,
    <DateTimeInput source="dateTo" name={"dateTo"} label={"Dátum eddig"} key={4}/>
];

const exporter = (values: any, type: string) => {
    const currentDate = new Date();
    const excelValues = values.map((v: any, index: number) => {
        if(type == 'EInvoice') {
            return {
                'ID': v.id,
                'Felhasználó': v.user !== null ? v.user.name : 'DELETED',
                'Létrehozás ideje': v.createdAt,
            }
        }
        return {
            'ID': v.id,
            'Felhasználó': v.user !== null ? v.user.name : 'DELETED',
             'Cím': v.address,
            'Státusz': getRequestStatusName(v.status.status),
             'Létrehozás ideje': v.createdAt,
        }
    });
    const title = type === 'EInvoice' ? 'elektronikus_szamla_igenylesek' : 'tartozasmentessegi_igazolas_igenylesek'
    exportExcel({
        excelData: excelValues,
        fileName: `${title}-${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}`
    }).then(r => {
    });
};

export const RequestList = (props: RequestListProps) => {
    const path = useLocation();
    const user = getJwtData()
    const redirect = useRedirect()
    const refresh = useRefresh();

    useEffect(() => {
        const id = setInterval(() => {
                refresh()
            }
            , 5000);

        return () => clearInterval(id);
    }, []);

    return <List title={props.title}
                 filters={requestFilters}
                 exporter={props.type === 'PostInvoice' ? false : (values) => exporter(values, props.type)}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <ReferenceField source="userId"
                            reference="web-user"
                            link="show"
                            label={"Felhasználó"}>
                <TextField source="name"/>
            </ReferenceField>
            {!path.pathname.includes('EInvoice') && <TextField source="address" label={"Cím"} sortable={false}/>}
            {path.pathname.includes('PostInvoice') &&
                <TextField source="invoiceNumber" label={"Számlaszám"} sortable={false}/>}
            {path.pathname.includes('PostInvoice') &&
                <TextField source="partnerId" label={"Partner azonosító"} sortable={false}/>}
            {(path.pathname.includes('DebtFree') || path.pathname.includes('PostInvoice')) &&
                <FunctionField
                    label="Státusz"
                    render={(record: any) =>
                        <Tooltip
                            placement={'top'}
                            title={record.status.user !== null ?
                                `${record.status.user.name} - ${record.status.lastModified.substring(0, 10)} ${record.status.lastModified.substring(11, 19)}` : ''}
                            arrow>
                            <Chip style={{backgroundColor: getStatusColor(record.status.status), color: '#FFF'}}
                                  label={getRequestStatusName(record.status.status)}/>
                        </Tooltip>}
                />}
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"} showTime/>
            {(path.pathname.includes('DebtFree') || path.pathname.includes('PostInvoice')) &&
                <FunctionField
                    label=""
                    render={(record: any) => <Button variant={'contained'}
                                                     disabled={record.status.status === 'InProgress' && record.status.user.id !== user.id}
                                                     onClick={async () => {
                                                         redirect('show', path.pathname.includes('DebtFree') ? 'request/_DebtFree' : 'request/_PostInvoice', record.id);
                                                     }}>
                        Szerkesztés
                    </Button>}
                />}
        </Datagrid>
    </List>
};
