export const injectCustomMessages = (messages: any) => {
    messages.ra.navigation.page_rows_per_page = "Sorok száma oldalanként";
    messages.ra.auth.user_menu = "Felhasználó";
    messages.ra.action.unselect = "Ki";
    messages.ra.page.empty = "Nincs létrehozva ilyen elem";
    messages.ra.page.invite = "Hozz létre egyet!";
    messages.ra.action.move_up = "Mozgatás felfelé";
    messages.ra.action.move_down = "Mozgatás lefelé";
    messages['Not Authenticated'] = "Nincs Bejelentkezve";
    messages['Unauthorized'] = "Sikertelen azonosítás";


    return messages;
};
