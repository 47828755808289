import * as React from "react";
import {
    Edit,
    PasswordInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useRecordContext,
    useRedirect
} from 'react-admin';
import dataProvider from "../dataProvider";
import {WrappedWithBackButton} from "../wrapped-with-back-button";

const CustomToolbar = (props: any) => (
    <Toolbar
        {...props}
        sx={{display: 'flex', justifyContent: 'space-between'}}
    >
        <SaveButton/>
    </Toolbar>
);

const AdminUserForm = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    return <SimpleForm toolbar={<CustomToolbar/>}
                       onSubmit={async (values) => {
                           const neededValues = {
                               name: values.name,
                               email: values.email,
                               password: values.password === "" ? null : values.password,
                           }
                           await dataProvider.update('admin-user', {id: record.id, data: neededValues}).then(res => {
                               if (res.data) {
                                   notify(`Sikeres módosítás`, {type: 'success'});
                                   redirect('list', 'admin-user');
                               } else {
                                   notify(`Hiba történt`, {type: 'error'});
                               }
                           })
                       }}
    >
        <TextInput source="name" label={"Név"} name={"name"} required/>
        <TextInput source="email" label={"E-mail"} name={"email"} required/>
        <PasswordInput source="password" label={"Jelszó"} name={"password"}/>
    </SimpleForm>
}

export const AdminUserEdit = () => {
    return <WrappedWithBackButton>
        <Edit title={'Admin felhasználó szerkesztése'}>
            <AdminUserForm/>
        </Edit>
    </WrappedWithBackButton>
}
