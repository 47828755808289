import * as React from "react";
import {Create, PasswordInput, SimpleForm, TextInput} from 'react-admin';
import {WrappedWithBackButton} from "../wrapped-with-back-button";

export const AdminUserCreate = () => (
    <WrappedWithBackButton>
        <Create title={'Admin felhasználó hozzáadása'}>
            <AdminUserForm/>
        </Create>
    </WrappedWithBackButton>
);

const AdminUserForm = () => {
    return <SimpleForm>
        <TextInput source="name" label={"Név"} name={"name"} required/>
        <TextInput source="email" label={"E-mail"} name={"email"} required/>
        <PasswordInput source="password" label={"Jelszó"} name={"password"} required/>
    </SimpleForm>
}

