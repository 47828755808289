import * as React from "react";
import {Datagrid, DateField, FunctionField, List, ReferenceField, SelectInput, TextField, TextInput} from 'react-admin';
import {exportExcel} from "../export-excel";

export const getIssueTypeName = (type: string) => {
    switch(type) {
        case "Error":
            return "Hiba"
        case "Unique":
            return "Egyedi"
        case "Maintenance":
            return "Karbantartás"
        default:
            return "-"
    }
}

const issueReportFilters = [
    <TextInput source="email" label={"E-mail"} name={"email"} fullWidth required/>,
    <SelectInput source="type"
                 label={"Típus"}
                 choices={[
                     {id: 'Error', name: 'Hiba'},
                     {id: 'Unique', name: 'Egyedi'},
                     {id: 'Maintenance', name: 'Karbantartás'},
                 ]}/>
];

const exporter = (values: any) => {
    const currentDate = new Date();
    const excelValues = values.map((v: any, index: number) => {
        return {
            'ID': v.id,
            'Felhasználó': v.user.email,
            'Típus': getIssueTypeName(v.type),
            'Létrehozás ideje': v.createdAt,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `hibabejelentesek-${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}`
    }).then(r => {
    });
};

export const IssueReportList = () => (
    <List title={'Hibabejelentések'}
          exporter={exporter}
          filters={issueReportFilters}
    >
        <Datagrid bulkActionButtons={false} rowClick={"show"}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <ReferenceField source="user.id"
                            reference="web-user"
                            link="show"
                            label={"Felhasználó"}>
                <TextField source="email"/>
            </ReferenceField>
            <FunctionField
                label="Típus"
                render={(record: any) => `${getIssueTypeName(record.type)}`}
            />
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozás ideje"} showTime/>
        </Datagrid>
    </List>
);
