import * as React from "react";
import {BooleanField, Datagrid, DateField, List, TextField, TextInput} from 'react-admin';

const newsFilters = [
    <TextInput source="term" label={"Kulcsszó"} name={"term"} fullWidth required/>,
];

export const NewsList = () => (
    <List title={'Hírek'}
          sort={{field: 'createdAt', order: 'DESC'}}
          exporter={false}
          filters={newsFilters}
    >
        <Datagrid rowClick={'show'} bulkActionButtons={false}>
            <TextField source="id" label={"ID"}/>
            <TextField source="title" label={"Cím"}/>
            <TextField source="lead" label={"Alcím"}/>
            <BooleanField source={"isActive"} label={"Aktív"}/>
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozva"}/>
        </Datagrid>
    </List>
);
