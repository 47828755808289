import * as React from 'react';
import {FileField, FileInput, SaveButton, SimpleForm, TextInput, Toolbar, useNotify, useRefresh} from 'react-admin';
import myDataProvider from "../data-provider-with-image-convert";
import {Dialog} from "@material-ui/core";

const MyToolbar = () => {
    return (
        <Toolbar>
            <SaveButton
                label="Mentés"
            />
        </Toolbar>
    );
};

export const EditDialogWithAdminFiles = ({resource, record, open, setOpen}: {resource: string,  record: any, open: boolean, setOpen: any }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    return <Dialog open={open} onClose={() => setOpen(false)} PaperProps={{
        style: {
            backgroundColor: 'black',
            boxShadow: 'none',
        },
    }}>
        <SimpleForm toolbar={<MyToolbar/>}
            onSubmit={async (values) => {
        await myDataProvider.update(resource, {id: record.id, data: values}).then(res => {
            notify(`Sikeres módosítás`, {type: 'success'});
            refresh()
            setOpen(false)
        })
    }}>
        <TextInput source="insideNote"
                   label={"Belső megjegyzés"}
                   name={"insideNote"}
                   fullWidth
                   multiline/>
        <FileInput source="files" name={"files"} multiple label={"Fájlok"}>
            <FileField source="src" title="fileTitle"/>
        </FileInput>
    </SimpleForm>
    </Dialog>
};
