import * as React from "react";
import {Admin, CustomRoutes, Resource} from 'react-admin';
import {Route} from "react-router-dom";
import {CustomLayout} from "./layout/custom-layout";
import {NewsList} from "./news/news-list";
import {NewsCreate, NewsEdit} from "./news/news-create-and-edit";
import {NewsShow} from "./news/news-show";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {injectCustomMessages} from "./custom-admin-messages";
import {authProvider} from "./auth/authProvider";
import {AdminUserList} from "./admin-user/admin-user-list";
import {AdminUserCreate} from "./admin-user/admin-user-create";
import {customHungarianMessages} from "./hungarian-messages";
import PasswordChangePage from "./password-change/password-change-page";
import {ParamsList} from "./system-params/system-params-list";
import {ParamEdit} from "./system-params/params-edit";
import myDataProvider from "./data-provider-with-image-convert";
import {EmailTemplateEdit} from "./email-template/email-template-edit";
import {EmailTemplateList} from "./email-template/email-template-list";
import {LogList} from "./log/log-list";
import {brandingDarkTheme} from "./theme";
import {SurveyList} from "./survey/survey-list";
import {SurveyShow} from "./survey/survey-show";
import {RequestList} from "./request/request-list";
import {WebUserList} from "./web-user/web-user-list";
import {WebUserShow} from "./web-user/web-user-show";
import {IssueReportList} from "./issue-report/issue-report-list";
import {IssueReportShow} from "./issue-report/issue-report-show";
import {DocumentCreate} from "./document/document-create";
import {DocumentList} from "./document/document-list";
import {DocumentEdit} from "./document/document-edit";
import {DocumentRequestList} from "./document-request/document-request-list";
import {DocumentRequestShow} from "./document-request/document-request-show";
import {AdminUserEdit} from "./admin-user/admin-user-edit";
import {DocumentShow} from "./document/document-show";
import {DocumentGroupCreate, DocumentGroupEdit} from "./document-group/document-group-create-and-edit";
import {DocumentGroupList} from "./document-group/document-group-list";
import {RequestShow} from "./request/request-show";

const messages = {
    'hu': injectCustomMessages(customHungarianMessages),
};
const i18nProvider = polyglotI18nProvider(() => messages['hu'], 'hu');

const App = () => (
    <Admin dataProvider={myDataProvider}
           i18nProvider={i18nProvider}
           authProvider={authProvider}
           layout={CustomLayout}
           theme={brandingDarkTheme}
    >
        <Resource name="document-group"
                  list={DocumentGroupList}
                  create={DocumentGroupCreate}
                  edit={DocumentGroupEdit}
        />
        <Resource name="news"
                  list={NewsList}
                  edit={NewsEdit}
                  create={NewsCreate}
                  show={NewsShow}
        />
        <Resource name="admin-user"
                  list={AdminUserList}
                  create={AdminUserCreate}
                  edit={AdminUserEdit}
        />
        <Resource name="web-user"
                  list={WebUserList}
                  show={WebUserShow}
        />
        <Resource name="system-params"
                  list={ParamsList}
                  edit={ParamEdit}
        />
        <Resource name="email-template"
                  list={EmailTemplateList}
                  edit={EmailTemplateEdit}
        />
        <Resource name="log"
                  list={LogList}
        />
        <Resource name="survey"
                  list={SurveyList}
                  show={SurveyShow}/>
        <Resource name="issue-report"
                  list={IssueReportList}
                  show={IssueReportShow}/>
        <Resource name="document"
                  list={DocumentList}
                  create={DocumentCreate}
                  edit={DocumentEdit}
                  show={DocumentShow}
        />
        <Resource name="document-request"
                  list={DocumentRequestList}
                  show={DocumentRequestShow}/>
        <Resource name="request/_EInvoice"
                  list={<RequestList title={'Elektronikus számla igénylések'} type={'EInvoice'}/>}
        />
        <Resource name="request/_DebtFree"
                  list={<RequestList title={'Tartozásmentességi igazolás igénylések'} type={'DebtFree'}/>}
                  show={<RequestShow type={'DebtFree'}/>}/>
        <Resource name="request/_PostInvoice"
                  list={<RequestList title={'Postai számlaigénylések'} type={'PostInvoice'}/>}
                  show={<RequestShow type={'PostInvoice'}/>}/>
        <CustomRoutes>
            <Route path="/password-change" element={<PasswordChangePage/>}/>
        </CustomRoutes>
    </Admin>
);

export default App;
