import * as React from "react";
import {Box, Button} from "@mui/material";
import {Chip, Grid} from "@material-ui/core";
import {getRequestStatusName} from "./enum-translates";

enum Status {
    Free, InProgress, UnderDuty, Closed
}

interface WorkStatus {
    previous?: Status,
    status: Status,
    user?: {
        id: number,
        name: string,
        email: string
    },
    lastModified?: string
}

export const getStatusColor = (status: string) => {
    switch (status) {
        case "Free":
            return "blue"
        case "InProgress":
            return "orange"
        case "UnderDuty":
            return "purple"
        case "Closed":
            return "green"
        default:
            return "-"
    }
}

interface StatusChangeButtonProps {
    status: string
    changeStatus: (status: string) => {}
}

export const StatusChangeButton = (props: StatusChangeButtonProps) => {
    return <Button variant={'contained'}
                   sx={{
                       backgroundColor: getStatusColor(props.status),
                       transition: 'all 0.4s',
                       '&:hover': {
                           transform: 'scale(97%)',
                           backgroundColor: getStatusColor(props.status),
                       },
                   }}
                   onClick={async () => {
                       await props.changeStatus(props.status)
                   }}>
        {getRequestStatusName(props.status)}
    </Button>
}

export const StatusHistory = ({history}: { history: WorkStatus[] }) => {
    return <Box>
        <Box style={{color: '#B2BAC2', fontSize: '12px'}}>Státusz történet</Box>
        <Grid container style={{fontWeight: 700, margin: '15px 0'}}>
            <Grid xs={3}>
                Módosító
            </Grid>
            <Grid xs={3}>
                Előző státusz (miről)
            </Grid>
            <Grid xs={3}>
                Megadott státusz (mire)
            </Grid>
            <Grid xs={3}>
                Módosítás ideje
            </Grid>
        </Grid>
        {
            history.map((status, index) => {
                return <Grid container style={{padding: '15px 0', borderTop: '1px solid white'}} key={index}>
                    <Grid xs={3}>
                        {status.user?.name} ({status.user?.email})
                    </Grid>
                    <Grid xs={3}>
                        <Chip style={{
                            backgroundColor: getStatusColor(status.previous as unknown as string),
                            color: '#FFF'
                        }}
                              label={getRequestStatusName(status.previous as unknown as string)}/>
                    </Grid>
                    <Grid xs={3}>
                        <Chip
                            style={{backgroundColor: getStatusColor(status.status as unknown as string), color: '#FFF'}}
                            label={getRequestStatusName(status.status as unknown as string)}/>
                    </Grid>
                    <Grid xs={3}>
                        {status.lastModified ? `${status.lastModified.substring(0, 10)} ${status.lastModified.substring(11, 19)}` : ''}
                    </Grid>
                </Grid>
            })
        }
    </Box>
}
