import * as React from 'react';
import {FunctionField, Show, SimpleShowLayout, TextField, useShowContext} from 'react-admin';
import {getDocumentTypeName} from "./document-list";
import {Box, Typography} from "@mui/material";
import {WrappedWithBackButton} from "../wrapped-with-back-button";

export const DocumentShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"} sortable={false}/>
            <FunctionField
                label="Típus"
                render={(record: any) => `${getDocumentTypeName(record.type)}`}
            />
            {(record && record.hasOwnProperty("url")) && <Box sx={{marginBottom: '10px'}}>
                <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Fájl</Typography>
                <a href={`https://${record.url}`} target={'_blank'}>{record.url}</a>
            </Box>
            }
        </SimpleShowLayout>)
};
export const DocumentShow = () => {
    return <WrappedWithBackButton>
        <Show title={'Nyomtatvány'}>
            <DocumentShowLayout/>
        </Show>
    </WrappedWithBackButton>;
};
