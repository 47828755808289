import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

interface DownloadExcelButtonProps {
    excelData: any;
    fileName: string;
}

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportExcel = async (props: DownloadExcelButtonProps) => {
    const ws = XLSX.utils.json_to_sheet(props.excelData);
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, props.fileName + fileExtension);
}
