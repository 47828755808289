import React, {ReactNode} from "react";
import {Box, Stack} from "@mui/material";
import {BackButton} from "./back-button";

interface Props {
    children: ReactNode;
}

export const WrappedWithBackButton = (props: Props) => {
    return <Box>
        <Stack>
            <BackButton/>
        </Stack>
        {props.children}
    </Box>
};
