import * as React from "react";
import {Edit, FileField, FileInput, SelectInput, SimpleForm, TextInput, useRecordContext,} from 'react-admin';
import {Box, Typography} from "@mui/material";
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {documentTypes} from "./document-list";

const DocumentForm = () => {
    const record = useRecordContext();

    return <SimpleForm>
        <TextInput source="name" label={"Név"} name={"name"} required/>
        <SelectInput source="type" label={"Típus"}
                     disabled
                     choices={documentTypes}/>
        {(record && record.hasOwnProperty("url")) && <Box sx={{marginBottom: '10px'}}>
            <Typography sx={{fontSize: '0.75em', color: '#B2BAC2', marginBottom: '0.2em'}}>Jelenlegi fájl</Typography>
            <a href={`https://${record.url}`} target={'_blank'}>{record.url}</a>
        </Box>
        }
        <FileInput source="file" name={"file"}>
            <FileField source="src" title="Fájl"/>
        </FileInput>
    </SimpleForm>
}

export const DocumentEdit = () => (
    <WrappedWithBackButton>
        <Edit title={'Nyomtatvány szerkesztése'}>
            <DocumentForm/>
        </Edit>
    </WrappedWithBackButton>
);
