import * as React from "react";
import {Datagrid, List, Pagination, TextField, TopToolbar} from 'react-admin';
import {Stack} from "@mui/material";

export const ParamsList = () => (
    <List title={'Rendszer paraméterek'}
          actions={
              <TopToolbar sx={{width: '100%', paddingTop: '20px'}}/>
          }
    >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"} sortable={false}/>
            <TextField source="value" label={"Érték"} sortable={false}/>
        </Datagrid>
    </List>
);
