import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Button,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useNotify,
    useRedirect,
    useRefresh,
    useShowContext
} from 'react-admin';
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {getRequestStatusName} from "../enum-translates";
import {Box} from "@mui/material";
import {config} from "../config";
import {getJwtHeader} from "../auth/authProvider";
import {getStatusColor, StatusChangeButton, StatusHistory} from "../StatusHistory";
import {Chip, Grid} from "@material-ui/core";
import {EditDialogWithAdminFiles} from "./EditDialogWithAdminFiles";
import myDataProvider from "../data-provider-with-image-convert";

export const DocumentRequestShowLayout = () => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const [dialogOpen, setDialogOpen] = useState(false)
    useEffect(() => {
        const id = setInterval(() => {
                refresh()
            }
            , 5000);

        return () => clearInterval(id);
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }

    const changeStatus = async (status: string) => {
        fetch(`${config.apiUrl}/document-request/_${record.id}/status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getJwtHeader()
            },
            body: JSON.stringify({
                status
            })
        }).then(async res => {
            if (!res.ok) {
                await res.text().then(text => {
                    notify(text, {type: 'error'});
                })
            } else {
                notify(`Sikeres státusz változtatás!`, {type: 'success'});
                if (status === 'Free' || status === 'UnderDuty') {
                    redirect('list', 'document-request');
                } else {
                    refresh();
                }
            }
        })
    }

    return <SimpleShowLayout>
        <TextField source="id" label={"ID"} sortable={false}/>
        <ReferenceField source="user.id"
                        reference="web-user"
                        link="show"
                        label={"Felhasználó"}>
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="groupId"
                        reference="document-group"
                        link="show"
                        label={"Ügylettípus"}>
            <TextField source="name"/>
        </ReferenceField>
        <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozás ideje"} showTime/>
        <TextField source="note" label={"Megjegyzés (felhasználó)"}/>
        <div style={{color: '#B2BAC2', fontSize: '12px'}}>Csatolmányok</div>
        {record?.documents?.map((d: any) => {
                return <a href={`https://${d.url}`} target={'_blank'} style={{color: '#FFF'}}>{d.url}</a>
            }
        )}
        <TextField source="insideNote" label={"Admin megjegyzés"}/>
        <div style={{color: '#B2BAC2', fontSize: '12px'}}>Admin fájlok</div>
        {record?.adminFiles?.map((d: any) => {
            return <Grid container>
                <Grid xs={12} md={3} style={{fontWeight: 600}}>{d.name}</Grid>
                <Grid xs={12} md={6}><a href={`https://${d.url}`} style={{color: '#fff'}}
                                        target={'_blank'}>{`https://${d.url}`}</a></Grid>
                <Grid xs={12} md={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button variant={'outlined'}
                            label={'Törlés'}
                            style={{
                                color: '#EB0014',
                                border: '1px solid #EB0014'
                            }}
                            onClick={async () => {
                                await myDataProvider.update('document-request', {
                                    id: record.id,
                                    data: {
                                        insideNote: record.insideNote,
                                        adminFiles: record.adminFiles.filter((f:any) => f.url !== d.url)
                                    }
                                }).then(res => {
                                    notify(`Fájl sikeresen eltávolítva`, {type: 'success'});
                                    refresh()
                                })
                            }}
                    /></Grid>
            </Grid>
        })}
        <Box sx={{height: '5px'}}/>
        <Button variant={'contained'} label={'Megjegyzés/fájlok szerkesztése'} onClick={() => setDialogOpen(true)} style={{marginTop: '5px'}}/>
        {record && <EditDialogWithAdminFiles resource={'document-request'} record={record} open={dialogOpen} setOpen={setDialogOpen}/> }
        {record?.status?.status && <>
            <Box style={{color: '#B2BAC2', fontSize: '12px'}}>Státusz</Box>
            <Box style={{color: '#B2BAC2', fontSize: '12px', margin: '10px 0'}}>
                <Chip style={{backgroundColor: getStatusColor(record.status.status), color: '#FFF'}}
                      label={getRequestStatusName(record.status.status)}/>
            </Box>
            <Box style={{color: '#B2BAC2', fontSize: '12px', margin: '10px 0'}}>Státusz változtatás</Box>
            <Box style={{display: 'flex', gap: '20px'}}>
                {record.status.status !== 'Free' &&
                    <StatusChangeButton status={'Free'} changeStatus={changeStatus}/>}
                {record.status.status !== 'InProgress' &&
                    <StatusChangeButton status={'InProgress'} changeStatus={changeStatus}/>}
                {record.status.status !== 'UnderDuty' &&
                    <StatusChangeButton status={'UnderDuty'} changeStatus={changeStatus}/>}
                {record.status.status !== 'Closed' &&
                    <StatusChangeButton status={'Closed'} changeStatus={changeStatus}/>}
            </Box>
        </>}
        {record.statusHistory && <StatusHistory history={record.statusHistory}/>}
    </SimpleShowLayout>
};

export const DocumentRequestShow = () => {
    return <WrappedWithBackButton>
        <Show title={'Változásbejelentés'}>
            <DocumentRequestShowLayout/>
        </Show>
    </WrappedWithBackButton>;
};
