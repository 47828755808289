import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    Button,
    DateField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useNotify,
    useRedirect,
    useRefresh,
    useShowContext
} from 'react-admin';
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {getRequestStatusName} from "../enum-translates";
import {Box} from "@mui/material";
import {config} from "../config";
import {getJwtHeader} from "../auth/authProvider";
import {getStatusColor, StatusChangeButton, StatusHistory} from "../StatusHistory";
import {Chip} from "@material-ui/core";
import {RequestEditDialog} from "./DocumentRequestEditDialog";

export const RequestShowLayout = ({type}: { type: string }) => {
    const {
        error,
        isLoading,
        record,
    } = useShowContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    useEffect(() => {
        const id = setInterval(() => {
                refresh()
            }
            , 5000);

        return () => clearInterval(id);
    }, []);

    const [dialogOpen, setDialogOpen] = useState(false)

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }

    const changeStatus = async (status: string) => {
        fetch(`${config.apiUrl}/request/${record.id}/status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getJwtHeader()
            },
            body: JSON.stringify({
                status
            })
        }).then(async res => {
            if (!res.ok) {
                await res.text().then(text => {
                    notify(text, {type: 'error'});
                })
            } else {
                notify(`Sikeres státusz változtatás!`, {type: 'success'});
                if (status === 'Free' || status === 'UnderDuty') {
                    redirect('list', type === 'DebtFree' ? 'request/_DebtFree' : 'request/_PostInvoice');
                } else {
                    refresh();
                }
            }
        })
    }

    return <SimpleShowLayout>
        <TextField source="id" label={"ID"}/>
        <ReferenceField source="userId"
                        reference="web-user"
                        link="show"
                        label={"Felhasználó"}>
            <TextField source="name"/>
        </ReferenceField>
        {type === 'DebtFree' && <TextField source="partnerId" label={"Partnerkód/Díjfizető kód"}/>}
        {type === 'DebtFree' &&<TextField source="consumerLocationAddress" label={"Fogyasztási hely címe"}/>}
        {type === 'DebtFree' &&<TextField source="consumerLocationCode" label={"Fogyasztási hely kódja"}/>}
        <TextField source="address" label={"Cím"}/>
        <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozás ideje"} showTime/>
        <TextField source="insideNote" label={"Admin megjegyzés"}/>
        <Button variant={'contained'} label={'Megjegyzés szerkesztése'} onClick={() => setDialogOpen(true)} style={{marginTop: '5px'}}/>
        {(type === 'DebtFree' || type === 'PostInvoice') && record && <RequestEditDialog record={record} open={dialogOpen} setOpen={setDialogOpen}/> }
        {record?.status?.status && <>
            <Box style={{color: '#B2BAC2', fontSize: '12px'}}>Státusz</Box>
            <Box style={{color: '#B2BAC2', fontSize: '12px', margin: '10px 0'}}>
                <Chip style={{backgroundColor: getStatusColor(record.status.status), color: '#FFF'}}
                      label={getRequestStatusName(record.status.status)}/>
            </Box>
            <Box style={{color: '#B2BAC2', fontSize: '12px', margin: '10px 0'}}>Státusz változtatás</Box>
            <Box style={{display: 'flex', gap: '20px'}}>
                {record.status.status !== 'Free' &&
                    <StatusChangeButton status={'Free'} changeStatus={changeStatus}/>}
                {record.status.status !== 'InProgress' &&
                    <StatusChangeButton status={'InProgress'} changeStatus={changeStatus}/>}
                {record.status.status !== 'UnderDuty' &&
                    <StatusChangeButton status={'UnderDuty'} changeStatus={changeStatus}/>}
                {record.status.status !== 'Closed' &&
                    <StatusChangeButton status={'Closed'} changeStatus={changeStatus}/>}
            </Box>
        </>}
        {record.statusHistory && <StatusHistory history={record.statusHistory}/>}
    </SimpleShowLayout>
};

export const RequestShow = ({type}: { type: string }) => {
    return <WrappedWithBackButton>
        <Show title={'Változásbejelentés'}>
            <RequestShowLayout type={type}/>
        </Show>
    </WrappedWithBackButton>;
};
