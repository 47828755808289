import * as React from "react";
import {
    Create,
    Edit,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextInput, useNotify,
    useRecordContext, useRedirect,
} from 'react-admin';
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import dataProvider from "../dataProvider";

const DocumentGroupForm = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();

    return <SimpleForm onSubmit={async (values) => {
        const neededValues = {
            name: values.name,
            documentIds: values.documentIds,
        }
        await dataProvider.update('document-group', {id: record.id, data: neededValues}).then(res => {
            if (res.data) {
                notify(`Sikeres módosítás`, {type: 'success'});
                redirect('list', 'document-group');
            } else {
                notify(`Hiba történt`, {type: 'error'});
            }
        })
    }}>
        <TextInput source="name" label={"Név"} name={"name"} required fullWidth/>
        <ReferenceArrayInput source="documentIds" reference="document" name={'documentIds'}>
            <SelectArrayInput fullWidth label={'Nyomtatványok'}/>
        </ReferenceArrayInput>
    </SimpleForm>
}

export const DocumentGroupEdit = () => (
    <WrappedWithBackButton>
        <Edit title={'Ügylettípus szerkesztése'}>
            <DocumentGroupForm/>
        </Edit>
    </WrappedWithBackButton>
);

export const DocumentGroupCreate = () => (
    <WrappedWithBackButton>
        <Create title={'Ügylettípus létrehozása'}>
            <SimpleForm>
                <TextInput source="name" label={"Név"} name={"name"} required fullWidth/>
                <ReferenceArrayInput source="documentIds" reference="document" name={'documentIds'}>
                    <SelectArrayInput fullWidth label={'Nyomtatványok'}/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    </WrappedWithBackButton>
);
