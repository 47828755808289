import * as React from "react";
import {useEffect} from "react";
import {
    Datagrid,
    DateField,
    DateTimeInput,
    FunctionField,
    List,
    ReferenceField,
    SelectArrayInput,
    SelectInput,
    TextField,
    TextInput,
    useRedirect,
    useRefresh
} from 'react-admin';
import {getRequestStatusName} from "../enum-translates";
import {Chip} from "@material-ui/core";
import {getStatusColor} from "../StatusHistory";
import {Button, Tooltip} from "@mui/material";
import {getJwtData} from "../auth/authProvider";
import {exportExcel} from "../export-excel";

const documentFilters = [
    <TextInput source="name" label={"Nyomtatvány"} name={"name"} fullWidth key={1}/>,
    <SelectArrayInput source="status"
                      label={"Státusz"}
                      choices={[
                          {id: 'Free', name: 'Foglalható'},
                          {id: 'InProgress', name: 'Folyamatban'},
                          {id: 'UnderDuty', name: 'Feldolgozás alatt'},
                          {id: 'Closed', name: 'Lezárva'},
                      ]}
                      defaultValue={[
                          'Free',
                          'InProgress',
                          'UnderDuty'
                      ]}
                      key={2}/>,
    <SelectInput source="type"
                 label={"Típus"}
                 choices={[
                     {id: 'Request', name: 'Kérés'},
                     {id: 'PersonalContract', name: 'Szerződés'},
                     {id: 'PostInvoice', name: 'Postai számla'},
                     {id: 'Error', name: 'Hiba'},
                     {id: 'Unique', name: 'Egyedi'},
                     {id: 'Maintenance', name: 'Karbantartás'},
                 ]}
                 key={3}/>,
    <TextInput source="query" label={"Név/E-mail"} name={"query"} key={4}/>,
    <DateTimeInput source="dateFrom" name={"dateFrom"} label={"Dátum ettől"} key={5}/>,
    <DateTimeInput source="dateTo" name={"dateTo"} label={"Dátum eddig"} key={6}/>
];

const exporter = (values: any) => {
    const currentDate = new Date();
    const excelValues = values.map((v: any, index: number) => {
        return {
            'ID': v.id,
            'Felhasználó': v.user.name,
            'Ügylettípus': v.documentGroup.name,
            'Státusz': getRequestStatusName(v.status.status),
            'Létrehozás ideje': v.createdAt,
        }
    });
    exportExcel({
        excelData: excelValues,
        fileName: `valtozas-bejelentesek-${currentDate.getFullYear()}_${currentDate.getMonth() + 1}_${currentDate.getDate()}`
    }).then(r => {
    });
};


export const DocumentRequestList = () => {
    const user = getJwtData()
    const redirect = useRedirect()
    const refresh = useRefresh();

    useEffect(() => {
        const id = setInterval(() => {
                refresh()
            }
            , 5000);

        return () => clearInterval(id);
    }, []);
    return <List title={'Változásbejelentések'}
                 filters={documentFilters}
                 exporter={exporter}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="user.name" label={"Felhasználó"}/>
            <TextField source="documentGroup.name" label={"Ügylettípus"}/>
            <FunctionField
                label="Státusz"
                render={(record: any) =>
                    <Tooltip
                        placement={'top'}
                        title={record.status.user !== null ?
                            `${record.status.user.name} - ${record.status.lastModified.substring(0, 10)} ${record.status.lastModified.substring(11, 19)}` : ''}
                        arrow>
                        <Chip style={{backgroundColor: getStatusColor(record.status.status), color: '#FFF'}}
                              label={getRequestStatusName(record.status.status)}/>
                    </Tooltip>}
            />
            <DateField source="createdAt" locales={['hu-HU']} label={"Létrehozás ideje"} showTime/>
            <FunctionField
                label=""
                render={(record: any) => <Button variant={'contained'}
                                                 disabled={record.status.status === 'InProgress' && record.status.user.id !== user.id}
                                                 onClick={() => {
                                                     redirect('show', 'document-request', record.id);
                                                 }}>
                    Szerkesztés
                </Button>}
            />
        </Datagrid>
    </List>
};
