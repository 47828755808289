import * as React from 'react';
import {
    ArrayField,
    Datagrid,
    DateField,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useShowContext
} from 'react-admin';
import {getInvoiceTransactionStatusName} from "../enum-translates";
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {Typography} from "@mui/material";

const WebUserShowLayout = () => {
    const {
        error,
        isLoading,
        record
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <SimpleShowLayout>
            <TextField source="id" label={"ID"} sortable={false}/>
            <TextField source="name" label={"Név"}/>
            <TextField source="phone" label={"Telefonszám"}/>
            <TextField source="email" label={"E-mail"}/>
            <DateField source="registeredAt" locales={['hu-HU']} label={"Regisztrálás ideje"}/>
            <TextField source="taxNumber" label={"Adószám"}/>
            <Typography sx={{
                color: '#B2BAC2',
                fontSize: 12,
            }}>Partner azonosítók</Typography>
            {record.partnerIds.map((rId: string) => {
                return <div>{rId}</div>
            })
            }
            <ArrayField source="declarations" label={"Bejelentések"}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="consumerLocationCode" label={"Kód"}/>
                    <FunctionField
                        label="Státusz"
                        render={(record: any) => `${getInvoiceTransactionStatusName(record.status)}`}
                    />
                    <DateField source="createdAt" locales={['hu-HU']} label={"Dátum"}/>
                </Datagrid>
            </ArrayField>
            <ArrayField source="payments" label={"Tranzakciók"}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="transactionCode" label={"Tranzakció kód"}/>
                    <FunctionField
                        label="Státusz"
                        render={(record: any) => `${getInvoiceTransactionStatusName(record.status)}`}
                    />
                    <TextField source="message" label={"Üzenet"}/>
                    <DateField source="createdAt" locales={['hu-HU']} label={"Dátum"}/>
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    );
};


export const WebUserShow = () => {
    return <WrappedWithBackButton>
        <Show title={'Felhasználó'}>
            <WebUserShowLayout/>
        </Show>
    </WrappedWithBackButton>;
};
