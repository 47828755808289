import * as React from "react";
import {Create, FileField, FileInput, SelectInput, SimpleForm, TextInput,} from 'react-admin';
import {WrappedWithBackButton} from "../wrapped-with-back-button";
import {documentTypes} from "./document-list";

export const DocumentCreate = () => (
    <WrappedWithBackButton>
        <Create title={'Nyomtatvány létrehozása'}>
            <SimpleForm>
                <TextInput source="name" label={"Név"} name={"name"} required/>
                <SelectInput source="type"
                             label={"Típus"}
                             choices={documentTypes}/>
                <FileInput source="file" name={"file"}>
                    <FileField source="src" title="Fájl"/>
                </FileInput>
            </SimpleForm>
        </Create>
    </WrappedWithBackButton>
);
